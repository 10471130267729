<template>
  <div class="search_city" :class="[ { fullscreen: fullscreen } ]">
    <div id="search_opaque" @click="$emit('close')"></div>
    <div class="input" :class="{ search_etat: search_etat }" id="inp" @click="restart">
      <div class="ico_main">
        <svg width="24px" height="24px" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="presentation" aria-hidden="true" focusable="false"><path d="M17.5834 5.16602C14.5001 2.08268 9.50008 2.08268 6.41675 5.16602C3.33341 8.24935 3.33341 13.3327 6.41675 16.416L12.0001 21.9993L17.5834 16.3327C20.6667 13.3327 20.6667 8.24935 17.5834 5.16602ZM12.0001 12.416C11.0834 12.416 10.3334 11.666 10.3334 10.7493C10.3334 9.83268 11.0834 9.08268 12.0001 9.08268C12.9167 9.08268 13.6667 9.83268 13.6667 10.7493C13.6667 11.666 12.9167 12.416 12.0001 12.416Z" fill="#000000"></path></svg>
      </div>

      <input ref="search_m" type="text" :placeholder="label" v-model="search_m" v-$model="search_m">


      <transition name="slide-fade">
        <div class="result_list" v-if="search_etat === true">
          <div class="" v-for="n in select" :key="n.code" @click="result(n)">
            <div class="elem" v-if="n.streetName">
              <div class="ico">
                <svg width="22px" height="22px" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="presentation" aria-hidden="true" focusable="false"><path d="M17.5834 5.16602C14.5001 2.08268 9.50008 2.08268 6.41675 5.16602C3.33341 8.24935 3.33341 13.3327 6.41675 16.416L12.0001 21.9993L17.5834 16.3327C20.6667 13.3327 20.6667 8.24935 17.5834 5.16602ZM12.0001 12.416C11.0834 12.416 10.3334 11.666 10.3334 10.7493C10.3334 9.83268 11.0834 9.08268 12.0001 9.08268C12.9167 9.08268 13.6667 9.83268 13.6667 10.7493C13.6667 11.666 12.9167 12.416 12.0001 12.416Z" fill="#000000"></path></svg>
              </div>
              <div class="txt">
                <div class="ville">{{ n.streetNumber }} {{ n.streetName }}</div>
                <div class="details">{{ n.zipcode }} {{ n.city }}, France</div>
              </div>
            </div>
          </div>
        </div>
      </transition>


    </div>
  </div>
</template>

<script>


export default {
  name: "search_ville",
  data() {
    return {
      search_m: '',
      last_search_m: '',
      select: [],
      
      interval_search: null,
      
      result_etat: false,

      search_etat: false
    }
  },
  props: [ 'label', 'fullscreen', 'show', 'address_load' ],
  watch: {
    $ville() {
      this.label = this.$ville
    },
    show() {
      let self = this
      setTimeout(function() {
        self.$refs.search_m.focus()
        self.restart()
        self.search_etat = true
      //   self.search()
      })
      // this.restart()
    },
    search_m() {
      let self = this
      if(self.search_m.length > 1 && self.result_etat === false) {
        self.$http.get('/geo/adresse/' + self.search_m).then((response) => {
          console.log(response.data)
          if(self.result_etat === false) {
            self.select = response.data
            self.last_search_m = self.search_m
          }
        }).catch(e => console.log(e))


      } else if(self.search_m.length <= 0)
        self.select = []
    },
    address_load() {
      if(this.address_load)
        this.result(this.address_load)
    }
    // search_m(value) {
    //   console.log(value)
    //   if(this.search_m.length > 1) {
    //     this.$http.get('https://geo.api.gouv.fr/communes?limit=10&nom=' + this.search_m).then((response) => {
    //       this.select = response.data
    //       this.last_search_m = this.search_m
    //     }).catch(e => console.log(e))
    //   } else if(this.search_m.length <= 0)
    //     this.select = []
    // }
  },
  created() {
    let self = this
    setTimeout(function() {
      document.querySelector('.search_city input').focus()
      self.restart()
    }, 10)
    

    // this.search()
    // let search = document.querySelector('.search_city input')
    // search.addEventListener('change', function() {
    // 
    // })


    window.addEventListener('keypress', function(){
      self.search_etat = true
      self.restart()
    })
    window.addEventListener('click', function(e){
      if (document.getElementById('inp') && document.getElementById('inp').contains(e.target))
        self.search_etat = true
      else {
        self.search_etat = false
      }
    });
  },
  methods: {
    // search() {
    //   if(this.result_etat === true) {
    //     return
    //   }
      
      // if(this.interval_search)
      //   clearInterval(this.interval_search)
      
      // let self = this
      // this.interval_search = setInterval(function() {
      //  
      // }, 200)
    // },
    change() {

    },
    result(city) {
      console.log(city)
      // clearInterval(this.interval_search)
      this.result_etat = true
      this.search_etat = false
      // this.$ville = { name: city.nom, cp: city.codesPostaux[0], round: 0 }
      if(city.streetNumber)
        this.search_m = city.streetNumber + ' ' + city.streetName + ', ' + city.zipcode + ' ' + city.city
      else 
        this.search_m = city.streetName + ', ' + city.zipcode + ' ' + city.city
        
        
      this.select = []
      this.$emit('result', city)
    },
    restart() {
      // if(this.result_etat === false)
      //   this.search()
      
      this.result_etat = false
    }
  }
}
</script>

<style scoped>
.search_city { background: #fff; width: 100%; margin-bottom: 16px; display: flex; align-items: center; position: relative;
  flex-direction: column; justify-content: center; z-index: 99;
}
.search_city .input { display: flex; align-items: center; width: 100%; background: #eee; position: relative; }
.search_city .input .ico_main { margin: 0 16px; position: absolute; top: 0; bottom: 0; display: flex; align-items: center }
.search_city .input input { width: 100%; font-size: 16px; height: 50px; border: none; background: none; outline: none; padding-left: 50px; transition: all .05s ease-in-out }
.search_city .input.search_etat:after { border-bottom: 2px solid #111; position: absolute; bottom: 0; left: 0; right: 0; content: '' }

.search_city .result_list { position: absolute; top: 50px; background: #fff; width: 100%; max-height: 40vh; overflow-y: auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); border-radius: 0 0 4px 4px;
  overflow-x: hidden;
}
.search_city .result_list .elem { display: flex; align-items: center; height: 76px; width: 100%; cursor: pointer }
.search_city .result_list .elem:hover { background: #f5f5f5 }
.search_city .result_list .elem .ico { width: 36px; height: 36px; display: flex; align-items: center; justify-content: center; margin: 0 11px }
.search_city .result_list .elem .ico svg { margin: 0 }
.search_city .result_list .elem .txt .ville { font-size: 16px; color: var(--boColor-gris1); padding-bottom: 1px; }
.search_city .result_list .elem .txt .details { font-size: 14px; color: var(--boColor-gris2) }

.search_city.fullscreen { position: fixed; top: 72px; right: 0; bottom: 0; left: 0; z-index: 100; max-width: inherit!important; margin: 0!important; padding: 0; background: none;
  justify-content: flex-start;
}
.search_city.fullscreen #search_opaque { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: rgba(20,20,20,.8) }
.search_city:not(.fullscreen) #search_opaque { display: none }

@media only screen and (min-width: 800px) {
  .search_city.fullscreen { padding: 0 35%; justify-content: center; top: 0; }
  .search_city.fullscreen .input { border-radius: 1px }
  .search_city.fullscreen .input input { height: 60px; }
  .search_city.fullscreen .result_list { top: 60px; }
}

</style>