<template>
  <div class="panier fc" :data-pay="pay_active"> 
    <svg @click="$router.go('-1')" class="close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
    <div class="title_top">Panier</div>
    
    <div class="int">
      
      <div class="note">
        <div class="textarea">
          <div class="t">Ajouter une note</div>
          <el-input placeholder="Exemple: Sans tomates" v-model="note" type="textarea" autosize maxlength="200"></el-input>
        </div>
      </div>
      
      <div class="couverts">
        Demander des couverts
        <el-checkbox v-model="couverts" id="couverts"></el-checkbox>
        <div class="click" @click="couverts = !couverts"></div>
      </div>
      
      <div class="livraison_price" v-if="livraison && livraison_price">
        <span class="txt">
          Livraison pour <span>{{ livraison.adresse.city }}</span>
        </span>
        <span class="price">{{ livraison_price | formatNumberdecimal }}€</span>
      </div>
      
      <div v-if="panier && panier.articles">
        <div class="article" v-for="(n, k) in panier.articles" :key="k">
          <div class="img">
            <img :src="$img_path + n.data.img_key" alt="">
            <div class="controles">
              <div class="nb">
                <div v-if="!not_update" class="moins" @click="change_nb('moins', k)"><i class="el-icon-minus"></i></div>
                <div class="i">{{ n.nb }}</div>
                <div v-if="!not_update" class="moins" @click="change_nb('add', k)"><i class="el-icon-plus"></i></div>
              </div>
              <div @click="delete_article(k)" class="delete"><i class="el-icon-delete"></i></div>
            </div>
          </div>
          <div class="right">
            <div class="title_line">
              <div class="title">{{ n.data.name }}</div>
              <div class="price">{{ n.data.final_price * n.nb | formatNumberdecimal }}€</div>
            </div>
            <div v-if="n.options.options && n.options.options.length" class="options_line">
              <div class="options">
                <div class="elem" v-for="(o, k1) in n.options.options" :key="k1">
                  {{ o.name }}
                  <div v-if="o.price" class="price">+{{ o.price * n.nb | formatNumberdecimal }}€</div>
                </div>
              </div>
              <div class="sous_total"><span><b>{{ (n.data.final_price + n.options.total) * n.nb | formatNumberdecimal }}€</b></span></div>
            </div>
<!--            <div class="buttons">-->
<!--              <div class="delete"><i class="el-icon-delete"></i></div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      
      
      <div v-if="boutique && (boutique.online || plan_choice || plan_select)">
    
        <div v-if="!pay_active && plan_choice" class="retrait_select">
          <div class="title">Maintenant ou plus tard ?</div>
          <div class="bloc">
            
            <div class="elem" v-if="boutique.retrait_active" :class="{ active: retrait }" @click="eat_pop('plan')">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z" /></svg>
              <span>
                Planifier
              </span>
            </div>
            
            <div class="elem" v-if="boutique.livraison_active" :class="{ active: livraison }" @click="eat_pop('now')" :data-disable="!boutique.online">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,17.5C14.33,17.5 16.3,16.04 17.11,14H6.89C7.69,16.04 9.67,17.5 12,17.5M8.5,11A1.5,1.5 0 0,0 10,9.5A1.5,1.5 0 0,0 8.5,8A1.5,1.5 0 0,0 7,9.5A1.5,1.5 0 0,0 8.5,11M15.5,11A1.5,1.5 0 0,0 17,9.5A1.5,1.5 0 0,0 15.5,8A1.5,1.5 0 0,0 14,9.5A1.5,1.5 0 0,0 15.5,11M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
              <span>
                Maintenant
                <i v-if="!boutique.online">Indisponible</i>
              </span>
            </div>
            
          </div>
        </div>
    
        <div v-if="!pay_active && !plan_choice" class="retrait_select">
          <div class="title">Récupérer ma commande <span v-if="plan_select">{{ plan_select.dayFormat }} vers {{ plan_select.hour }}</span></div>
          <div class="bloc">
            
            <div class="elem" v-if="boutique.retrait_active" :class="{ active: retrait }" @click="eat_pop('retrait')" 
                 :data-disable="(plan_choice && !plan_select) || (!plan_select && (!boutique.nextOpen.creneaux || (boutique.nextOpen.creneaux && !boutique.nextOpen.creneaux.length)))">
              <lottie-animation class="ico" :width="36" :height="36" path="lottie/place-marker2.json" :auto-play="false" />
              <span>
                Retrait
                <i v-if="(plan_choice && !plan_select) || (!plan_select && (!boutique.nextOpen.creneaux || (boutique.nextOpen.creneaux && !boutique.nextOpen.creneaux.length)))">Indisponible</i>
              </span>
            </div>
            
            <div class="elem" v-if="boutique.livraison_active" :class="{ active: livraison }" @click="eat_pop('livraison')">
              <lottie-animation class="ico" :width="36" :height="36" path="lottie/skateboarding.json" :auto-play="false" />
              <span>
                Livraison
                <i v-if="!plan_select">{{ $delayFormatClient(boutique.delay) }}</i>
              </span>
            </div>
            
          </div>
        </div>
    
        <div class="commander_line pay" v-if="pay_active === false">
          <button @click="login_etat = true" v-if="!$store.state.user.logged">Connectez-vous</button>
          <button_plus :rounded="true" v-else-if="livraison_min_error" :disabled="!submit_active" txt="Commander" 
                       :txt_sub="'Montant minimum : ' + this.boutique.livraison_min_price[this.livraison.adresse.citycode] + '€'" 
                       txt_load="Vérification" :fw="true" @success="commande_start"></button_plus>
          <button_plus :rounded="true" v-else :disabled="!submit_active" txt="Commander" txt_load="Vérification" :fw="true" @success="commande_start"></button_plus>
<!--          <button_plus class="fw" @success="login_etat = true" v-else>-->
<!--          </button_plus>-->
    
          <span v-if="panier" class="total">Total <b>{{ panier.total | formatNumberdecimal }}€</b></span>
        </div>
        

        <div id="opaqueLogin" v-if="login_etat || register_etat" @click="register_etat = false; login_etat = false;"></div>
        <login v-if="login_etat" @create="register_etat = true; login_etat = false" class="user_lo" @success="login_etat = false; $forceUpdate()"></login>
        <register class="user_lo" v-if="register_etat" @success="register_etat = false; $forceUpdate()"></register>

        <eat_retrait v-if="!pay_active" @close="eat_retrait_etat = false" :show="eat_retrait_etat" @success="eat_retrait_success"></eat_retrait>
        <eat_livraison v-if="!pay_active" @close="eat_livraison_etat = false" :show="eat_livraison_etat" @success="eat_livraison_success"></eat_livraison>
        <eat_plan v-if="!pay_active" @close="plan_active = false" :show="plan_active" @success="plan_success"></eat_plan>
    
        <div class="paiement" v-if="pay_active === true">
          <paiement class="paiement" :pay_id="pay_id" :client_secret="client_secret" :total="panier.total" button_txt="Payer" button_txt_load="Paiement en cours" @success="command_success"></paiement>
        </div>

      </div>

      <div v-if="boutique && !boutique.online && !plan_choice && !plan_select" class="retrait_select closed">
        <span>
          Votre restaurant n'est pas disponible
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import eat_livraison from "@/components/panier/eat_livraison";
import eat_retrait from "@/components/panier/eat_retrait";
import eat_plan from "@/components/panier/eat_plan";
import paiement from "@/components/global/paiement";
import login from "@/components/user/login";
import register from "@/components/user/register";
export default {
  name: "home",
  data() {
    return {
      boutique:null,
      panier: null,
      panier_brut: null,
      pay_active: false,
      
      plan_select: null,
      plan_active: false,
      plan_choice: false,
      
      
      eat_livraison_etat: false,
      livraison: null,
      livraison_price: 0,
      
      eat_retrait_etat: false,
      retrait: null,

      type: null,
      submit_active: false,
      not_update: false,
      
      note: null,
      couverts: false,
      
      pay_id: null, client_secret: null,
      
      login_etat: false,
      register_etat: false,
      
      livraison_min_error: false,

    }
  },
  created() {
    this.$loader = true
    this.sync()
  },
  components: {
    LottieAnimation,
    eat_livraison,
    eat_retrait,
    eat_plan,
    paiement,
    login,
    register
  },
  methods: {
    sync() {
      this.$http.get('/boutique/' + this.$route.params.boutique_id).then((response) => {
        this.boutique = response.data.boutique
        if(this.boutique.plan_active) {
          this.plan_choice = true
        }
        this.$loader = false
      })
      
      this.panier_brut = JSON.parse(localStorage.getItem("panier"))
      this.$http.post('/purchase/panier_calc', { panier: this.panier_brut[this.$route.params.boutique_id] }).then((response) => {
        this.panier = response.data
        this.panier_brut[this.$route.params.boutique_id] = this.panier.panier
        localStorage.setItem("panier", JSON.stringify(this.panier_brut))
        
        if(this.boutique 
            && this.type
            && this.type === 'livraison' 
            && this.livraison
            && this.livraison.adresse
            && this.livraison.adresse.citycode
            && this.boutique.livraison_min_price[this.livraison.adresse.citycode] 
            && this.panier.total < this.boutique.livraison_min_price[this.livraison.adresse.citycode]) {
          // this.livraison = null
          this.submit_active = false
          this.livraison_min_error = true
        }
        else if(this.boutique && this.type && this.type === 'livraison') {
          this.submit_active = true
          this.livraison_min_error = false
        }
        else {
          this.livraison_min_error = false
        }


            }).catch(() => {
        this.$router.go('-1')
      })
    },
    change_nb(direction, k) {
      this.panier_brut = JSON.parse(localStorage.getItem("panier"))
      if(((direction === 'add' && this.panier.articles[k].nb < this.panier.articles[k].data.can_buy_nb) || direction === 'moins')) {
        if(direction === 'add' && this.panier_brut[this.$route.params.boutique_id][k].nb < 5)
          this.panier_brut[this.$route.params.boutique_id][k].nb++
        else if(direction === 'moins' && this.panier_brut[this.$route.params.boutique_id][k].nb > 1)
          this.panier_brut[this.$route.params.boutique_id][k].nb--
        // else if(direction === 'moins' && this.panier_brut[this.$route.params.boutique_id][k].nb === 1) {
        //   if(confirm('Voulez-vous vraiment supprimer ce produit ?')) {
        //     this.panier_brut[this.$route.params.boutique_id].splice(k, 1)
        //   }
        // }

        console.log('change_nb')
        localStorage.setItem("panier", JSON.stringify(this.panier_brut))
        this.sync()
      }
    },
    delete_article(k) {
      this.panier_brut = JSON.parse(localStorage.getItem("panier"))
        
      if(confirm('Voulez-vous vraiment supprimer ce produit ?')) {
        this.panier_brut[this.$route.params.boutique_id].splice(k, 1)
        localStorage.setItem("panier", JSON.stringify(this.panier_brut))
        this.sync()
      }
    },
    eat_pop(key) {
      if(key === 'retrait') {
        this.livraison_min_error = false
        if(!this.plan_select && !this.plan_choice) {
          if(this.boutique.nextOpen && this.boutique.nextOpen.creneaux && Object.keys(this.boutique.nextOpen.creneaux).length) 
            this.eat_retrait_etat = true
        }
        else {
          this.livraison = null
          this.retrait = this.plan_select.hour
          this.eat_retrait_etat = false
          this.submit_active = true
          this.type = 'retrait'
        }
      }
      if(key === 'livraison') {
        this.eat_livraison_etat = true
      }
      if(key === 'plan') {
        this.plan_active = true
      }
      if(key === 'now') {
        if(this.boutique.online)
          this.plan_choice = false
      }
    },
    plan_success(d) {
      this.plan_select = d
      this.plan_choice = false
      this.plan_active = false
    },
    eat_livraison_success(d) {
      this.retrait = null
      this.livraison = d
      this.eat_livraison_etat = false
      this.type = 'livraison'
      
      if(this.boutique.livraison_prices && this.boutique.livraison_prices[d.adresse.citycode]) {
        this.livraison_price = this.boutique.livraison_prices[d.adresse.citycode]
        this.panier.total += parseFloat(this.livraison_price)
      }
      if(this.boutique.livraison_min_price && this.boutique.livraison_min_price[d.adresse.citycode] && this.panier.total < this.boutique.livraison_min_price[d.adresse.citycode]) {
        this.livraison_min_error = true
        this.submit_active = false
      }
      else {
        this.livraison_min_error = false
        this.submit_active = true
      }
      
    },
    eat_retrait_success(d) {
      this.livraison_min_error = false
      this.livraison = null
      this.retrait = d
      this.eat_retrait_etat = false
      this.submit_active = true
      this.type = 'retrait'
    },
    commande_start() {
      if (this.submit_active !== true) return

      this.not_update = true


      let livraison = {}
      if (this.type === 'retrait') {
        livraison = {
          type: this.type, hour: this.retrait
        }
      } else if (this.type === 'livraison') {
        if (!this.livraison) return

        livraison = {
          type: this.type, adresse: this.livraison
        }
      }

      this.$http.post('/purchase/start', {
        boutique_id: this.$route.params.boutique_id, amount: this.panier.total, livraison: livraison, panier: this.panier_brut[this.$route.params.boutique_id], note: this.note, couverts: this.couverts,
        plan: this.plan_select
      }).then((r) => {
        console.log(r)
        this.client_secret = r.data.client_secret
        this.pay_id = r.data.pay_id
        this.pay_active = true
      })
    },
    command_success(id) {
      this.panier[this.$route.params.boutique_id] = []
      localStorage.setItem("panier", JSON.stringify(this.panier))
      this.total = 0
      // this.command_success_etat = true
      this.$forceUpdate()
      if(!this.plan_select)
        this.$router.push({ name: 'commande_view', params: { commande_id: id } })
      else
        this.$router.push({ name: 'user_compte_commandes' })
    },
  }
}
</script>

<style>
  .panier .note { margin-bottom: 16px; }
  .panier .note .t { font-size: 16px; margin-bottom: 8px; }
  .panier .note .textarea { background: var(--gris); border-radius: 6px; padding: 16px }
  .panier .note .textarea .el-textarea__inner { border: none; padding-left: 0; padding-right: 0; background: none }
  
  .couverts { display: flex; align-items: center; justify-content: space-between; font-size: 16px; background: #fff; margin-bottom: 8px; padding: 18px 16px 16px 16px; position: relative; }
  .couverts .el-checkbox { padding-bottom: 0; margin-left: 8px; transform: scale(1.4); transform-origin: right center; }
  .couverts .click { position: absolute; top: 0; right: 0; bottom: 0; left: 0; cursor: pointer }
  
  .fc { background: #f5f5f5; z-index: 100; position: fixed; top: 0; right: 0; bottom: 0; left: 0; padding: 80px 20px 0 20px; overflow-y: auto }
  .fc .close { position: absolute; left: 20px; top: 20px; z-index: 5; background: var(--blanc-button); fill: var(--black-sub); width: 40px; height: 40px; border-radius: 6px; cursor: pointer }
  .fc .title_top { position: absolute; left: 0; right: 0; top: 26px; text-align: center; font-weight: 600; }

  
  .commander_line.pay { padding: 16px 20px!important; display: flex; align-items: center; justify-content: space-between; position: fixed; background: #fff; bottom: 0; right: 0; left: 0;
    border-radius: 30px 30px 0 0; box-shadow: 0 -4px 50px rgba(204,204,204,.5)
  }
  .commander_line.pay .total { padding-left: 20px; font-size: 16px }
  .commander_line.pay .total b { font-size: 26px }
  .commander_line.pay button { width: 100%; }


  @media only screen and (min-width: 800px) {
    .fc .int { width: 700px; position: relative; margin: auto; }
    .fc .commander_line.pay { width: 700px; margin: auto; position: fixed; }
    .fc .paiement { width: 700px; margin: auto; border-radius: 16px 16px 0 0 }

    /*.fc .form_pay { padding: 0!important; }*/
  }

</style>
<style scoped>
  
  .fc { padding-bottom: 200px; }
  
  #opaqueLogin { position: fixed; bottom: 0; left: 0; right: 0; top: 0; background: rgba(10,10,10,.4); z-index: 10 }
  .user_lo { position: fixed; bottom: 0; left: 0; right: 0; max-height: calc(100vh - 80px); background: #fff; box-shadow: 0 -4px 50px rgba(204,204,204,.5); border-radius: 16px 16px 0 0; 
    overflow-y: auto; z-index: 11 }
  /*.user_lo { position: relative; z-index: 10 }*/

  .article { display: flex; background: #fff; border-radius: 6px; margin-bottom: 16px; padding: 16px; position: relative; }
  .article .buttons { display: flex; justify-content: flex-end; margin-top: 16px; }
  .article .delete { height: 28px; background: var(--gris); display: flex; align-items: center; justify-content: center;
     border-radius: 6px; font-size: 10px; padding: 0 6px; text-align: center; max-width: 85px; margin: 8px auto 0 auto; cursor: pointer;
  }
  .article .delete i { font-size: 16px; }
  .article .img { width: 30%; }
  .article .img img { width: 100%; border-radius: 2px; }
  .article .right { display: flex; padding-left: 16px; padding-top: 6px; flex-direction: column; width: 70%; }
  .article .right .title_line { display: flex; justify-content: space-between; }
  .article .right .title_line .title { font-size: 16px; padding-right: 16px; }
  .article .right .title_line .price { font-size: 18px }
  .article .right .options_line { color: var(--black-sub); font-weight: 400; margin-top: 10px; padding-top: 8px; position: relative; }
  .article .right .options_line .price { font-size: 13px; }
  .article .right .options_line .options { width: 100%; }
  .article .right .options_line .options .elem { font-size: 14px; margin-bottom: 7px; display: flex; justify-content: space-between; align-items: center; width: 100%; }
  .article .right .options_line:before { content: ''; width: 40px; height: 2px; background: var(--gris); position: absolute; top: -5px }
  .article .right .sous_total { font-size: 14px; display: flex; justify-content: flex-end; margin-top: 18px; position: relative; color: var(--black-sub) }
  .article .right .sous_total:before { content: ''; width: 40px; height: 2px; background: var(--gris); position: absolute; top: -11px }
  .article .right .sous_total span { margin-left: 6px; }
  .article .right .sous_total b { font-weight: 500; color: var(--black-main); }


  .article .nb { display: flex; align-items: center; justify-content: center; width: 100%; padding: 0 6px; border-radius: 4px; margin-top: 6px; overflow: hidden }
  /*.article .nb .moins { width: 34px; height: 34px; display: flex; align-items: center; justify-content: center; background: #fff; cursor: pointer; }*/
  .article .nb .i { width: 30px; flex: 0 0 auto; display: flex; align-items: center; justify-content: center; }
  .article .nb .moins { width: 28px; height: 28px; flex: 0 0 auto; font-size: 16px; display: flex; align-items: center; justify-content: center; background: var(--gris); border-radius: 100%; cursor: pointer }

  .retrait_select { padding: 16px 20px 100px 20px; position: fixed; background: #fff; bottom: 0; right: 0; left: 0; box-shadow: 0 -4px 50px rgba(204,204,204,.5) }
  .retrait_select .title { font-size: 16px; margin-bottom: 14px; }
  .retrait_select .title span { color: var(--black-sub); font-weight: 400; padding-left: 6px; }
  .retrait_select .bloc { display: flex; align-items: center; justify-content: center;  }
  .retrait_select .elem { font-size: 16px; background: #f5f5f5; padding: 6px 6px 6px 3px; margin-right: 6px; width: 100%; border-radius: 6px; display: flex; align-items: center; justify-content: center;
    cursor: pointer; height: 48px;
  }
  .retrait_select .elem[data-disable] { opacity: .5; }
  .retrait_select .elem:hover { background: var(--gris) }
  .retrait_select .elem .ico { margin: 0!important; }
  .retrait_select .elem svg { fill: var(--black-main) }
  .retrait_select .elem span { padding-left: 14px; display: flex; flex-direction: column; justify-content: center; }
  .retrait_select .elem span i { color: var(--black-sub); font-style: normal; font-size: 12px; font-weight: 400; }
  .retrait_select .elem:last-child { margin-right: 0; }
  .retrait_select .elem.active { background: var(--gris-button); }
  .retrait_select.closed { align-items: center; justify-content: center; display: flex; font-weight: 400; font-size: 18px }
  .retrait_select.closed span { position: absolute; top: 50%; left: 0; right: 0; transform: translate(0, -50%); text-align: center }
  
  .paiement { position: fixed; bottom: 0; left: 0; right: 0; background: #fff; box-shadow: 0 -4px 50px rgba(204,204,204,.5); padding: 16px 20px 100px 20px }

  .fc[data-pay] { padding-bottom: 430px; }
  
  .livraison_price { display: flex; justify-content: space-between; align-items: center; font-size: 16px; height: 60px; margin-bottom: 16px; background: #fff; padding: 16px }
  .livraison_price .price { font-size: 18px }

  @media only screen and (min-width: 800px) {
    .retrait_select { width: 700px; margin: auto; border-radius: 16px 16px 0 0 }
    .user_lo { width: 480px; margin: auto; bottom: 50%; transform: translateY(50%); border-radius: 16px; box-shadow: none }
  }
  
</style>