<template>
  <div class="paiement" :class="{ no_padding: no_padding }" :data-bo="bo">
    <div class="title_a">Paiement par carte</div>
    <span v-if="txt_info" class="info tag">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
      {{ txt_info }}
    </span>
    <div class="form_pay">
      <div class="card_e">
        <div id="card"></div>
      </div>
      <span class="pay_error" v-if="error">{{ error }}</span>
    </div>
    

    <div class="commander_line pay">
      
      <button_plus :rounded="true" :disabled="disabled" :txt="button_txt" :txt_load="button_txt_load" :fw="true" @success="start" color="orange"></button_plus>

      <span class="total" v-if="total">Total <b>{{ total | formatNumberdecimal }}€</b></span>
    </div>
    <div class="commander_line details">
      Vos coordonnées bancaires sont cryptées et transmises directement via une connexion sécurisée à la plateforme de paiement. MonResto.Click vous garantit un achat sans risque et en toute confiance. Notre plateforme Stripe utilise la technologie SSL (cryptage en 128 bits) pour garantir une transaction complètement sécurisée lors du paiement.
    </div>
    <div class="propulse">
      Propulsé par <svg class="InlineSVG Icon Footer-PoweredBy-Icon Icon--md" focusable="false" width="33" height="15"><g fill-rule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
    </div>
  </div>
</template>

<script>
import {loadStripe} from "@stripe/stripe-js";

export default {
  name: "paiement",
  data() {
    return {

      card: null,

      error: '',
  
      disabled : true,
      
      stripe: null
    }
  },
  props: [ 'client_secret', 'pay_id', 'insert_id', 'total', 'no_padding', 'button_txt', 'button_txt_load', 'txt_info', 'bo' ],
  mounted() {
    console.log(process.env.VUE_APP_STRIPE_KEY)
    loadStripe(process.env.VUE_APP_STRIPE_KEY).then((s) => {
      this.stripe = s

      let elements = s.elements()

      this.card = elements.create('card', {
        style: {
          base: {
            iconColor: '#444', color: '#444', '::placeholder': {
              color: '#7b7b7f',
            },
          }, invalid: {
            iconColor: '#f23f3f', color: '#f23f3f',
          },
        },
      })
      this.card.mount('#card')

      this.card.addEventListener('change', (event) => {
        console.log(event)
        if (event.error) {
          this.error = event.error.message
          this.disabled = true
        } else this.error = ''

        if (event.complete) this.disabled = false 
        else this.disabled = true

      })
    })
  },
  methods: {
    start() {
      if(this.bo) 
        this.start_abonnement()
      else 
        this.start_paiement()
    },
    start_abonnement() {
      console.log('Start Abonnement')
      this.disabled = true
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card
      }).then((r) => {
        if(r.error) {
          this.error = r.error.message + " Le paiement ne s'est pas effectué."
          this.disabled = false
        }
        else {
          console.log(r)
          this.$http.post('/bo/stripe/abonnement_start', { boutique_id: this.$store.state.user.user.boutique, paymentMethodId: r.paymentMethod.id } ).then(() => {
            this.$emit('success')
          }).catch((e) => {
            if(!e)
              this.error = "Erreur, le paiement ne s'est pas effectué"
            else this.error = e.response.data
            this.disabled = false
          })
        }
      })
      
    },
    start_paiement() {
      if(!this.client_secret) {
        this.error = "Erreur inconnue, le paiement ne s'est pas effectué"
        return
      }
      
      this.disabled = true
      this.error = ''
      
      this.stripe.confirmCardPayment(this.client_secret, {
        payment_method: {
          card: this.card, billing_details: {
            name: this.$store.state.user.user.nom + ' ' + this.$store.state.user.user.prenom,
            email: this.$store.state.user.user.email,
            phone: this.$store.state.user.user.international_phone_number
          }
        }
      }).then((r) => {
        if(r.error) {
          this.error = r.error.message + " Le paiement ne s'est pas effectué."
          this.disabled = false
        }
        else {
          this.$http.post('/purchase/pay_success', { pay_id: this.pay_id }).then((c) => {
            // alert('Paiement ok !')
            this.$emit('success', c.data)
          }).catch(() => this.error = "Erreur inconnue, le paiement ne s'est pas effectué")
        }
        console.log(r)
      }).catch(() => this.error = "Erreur inconnue, le paiement ne s'est pas effectué")
    }
  }
}
</script>

<style scoped>

.commander_line { padding: 16px 0px; display: flex; align-items: center; justify-content: space-between;  background: #fff; border-top: 1px solid #eee; }
.commander_line .total { padding-left: 20px; font-size: 16px }
.commander_line .total b { font-size: 26px }
.commander_line button { width: 100%; }
.commander_line.details { font-size: 14px; line-height: 20px; font-weight: 200; }

.commander_line.pay { position: fixed;  bottom: 0; right: 0; left: 0; z-index: 100 }
.paiement[data-bo] .commander_line.pay { background: none!important; padding: 0!important; margin-top: 16px; box-shadow: none!important; }

.paiement.no_padding .commander_line { padding-left: 0; padding-right: 0; }
.paiement.no_padding .commander_line.pay { position: static; }

/*.card_e {  margin: 0 20px }*/
.form_pay { margin-top: 0px; margin-bottom: 10px; background: #fff;  }
.info { font-size: 12px; line-height: 14px; font-weight: 400; white-space: pre-line; display: flex; padding: 8px 6px; align-items: center; margin-bottom: 8px; }
.info svg { width: 36px; margin-right: 4px; }
/*.commander_line.pay { padding-bottom: 10px; }*/

/*.title_a { padding: 0 20px }*/

.pay_error { font-size: 14px; line-height: 16px; font-weight: 400; color: var(--boRed) }

.propulse { font-size: 14px; font-weight: 200; display: flex; align-items: center; margin-top: 6px; }
.propulse svg { margin-left: 6px; }

@media only screen and (min-width: 800px) {
  
  .commander_line {position: static; border-top: 0; margin-top: 4px; margin-bottom: 10px;}
  .commander_line.pay { position: static; }
  /*.card_e {  margin: 0 20px }*/
  .form_pay { margin-bottom: 0; margin-top: 0; }
  .propulse { margin-top: 0; }

  .info { margin-bottom: 8px; }

  .paiement.no_padding .commander_line { padding-left: 0; padding-right: 0; }
  .paiement.no_padding .form_pay { padding-left: 0; padding-right: 0; }

}
</style>