<template>
  <div class="eat_livraison" :data-show="show">
    <div @click="$emit('close')" class="bg"></div>
    <div class="int">
      <div class="int_top">
        
        <div @click="$emit('close')">
          <close></close>
        </div>
        
        <div class="title_a">Adresse de livraison</div>
        <search_adress :show="show" :address_load="address_load" label="Votre adresse" @result="adresse_select"></search_adress>
        
        <div class="loader" v-if="loader === true">
          <lottie-animation :width="150" :height="150" path="lottie/compass.json" />
        </div>
    
        <div v-if="adresse_ok === true">
          <input class="simple" v-model="batiment" type="text" placeholder="Bâtiment, bureau ou étage">
          <input class="simple" v-model="entreprise" type="text" placeholder="Entreprise">
          <input class="simple" v-model="instructions" type="text" placeholder="Instructions de livraison">
          <el-checkbox class="memory" v-model="memory" label="Mémoriser comme adresse par defaut" border></el-checkbox>
        </div>
  
        
        <div class="error" v-if="error">
          <i class="el-icon-error"></i>
          <span>{{ error }}</span>
        </div>

      </div>
      <div class="button_line">
        <button_plus :fw="true" :disabled="disabled" :rounded="true" color="green_simple" :click_not_can="true" @success="success" class="button_plus" txt="Enregistrer et continuer"></button_plus>
      </div>
    </div>
  </div>
</template>

<script>
import close from '../global/close'
import search_adress from "@/components/global/search_adress";

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "eat_livraison",
  data() {
    return {
      adresse: null,
      batiment: null,
      entreprise: null,
      instructions: null,
      
      memory: true,
      
      disabled: true,
      loader: false,
      
      adresse_ok: false,
      address_load: null,
      
      error: null
    }
  },
  props: [ 'show' ],
  components: {
    close,
    search_adress,
    LottieAnimation
  },
  mounted() {
    if(this.$store.state.user.user && this.$store.state.user.user.address) {
      console.log('Adress found')
      let a = this.$store.state.user.user.address
      console.log(a.adresse)
      this.address_load = a.adresse
      this.batiment = a.batiment
      this.entreprise = a.entreprise
      this.instructions = a.instructions
      this.adresse_select(a.adresse) 
    }
  },
  methods: {
    adresse_select(data) {
      this.loader = true
      this.adresse_ok = false
      this.disabled = true
      this.error = null
      data.boutique_id = this.$route.params.boutique_id
      this.$http.post('/boutique/livraison_can', data).then(() => {
        this.loader = false
        this.adresse_ok = true
        this.adresse = data
        this.disabled = false
        this.error = null
      }).catch((e) => {
        this.loader = false
        this.error = e.response.data
      })
    },
    success() {
      let r = {
        adresse: this.adresse,
        batiment: this.batiment,
        entreprise: this.entreprise,
        instructions: this.instructions,
      }
      
      if(this.memory)
        this.$http.post('/user/address', r)
      
      this.$emit('success', r)
    }
  }
}
</script>

<style scoped>

  .eat_livraison { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 150; display: none }
  .eat_livraison[data-show] { display: block }
  .eat_livraison .int { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: #fff; z-index: 150;}
  .eat_livraison .int .int_top { overflow-y: auto; position: absolute; top: 0; right: 0; bottom: 0; left: 0;  padding: 60px 20px 150px 20px; }
  
  .loader { margin-top: 20vh; }
  /*.eat_livraison .button_line { position: absolute; bottom: 0; right: 0; left: 0; }*/

  .eat_livraison .button_line { position: absolute; bottom: 0; left: 0; right: 0; padding: 20px 24px; background: #fff;box-shadow: 0 -4px 50px rgba(204,204,204,.5); z-index: 5; border-radius: 30px 30px 0 0 ;}
  /*.eat_livraison .button_line .button_plus { w100% }*/
  

  input.simple { background: #f5f5f5; width: 100%; font-size: 20px; height: 50px; border: none; outline: none; padding: 0 20px; margin-bottom: 16px; font-weight: 400; font-size: 16px;
    transition: all .05s ease-in-out; border-bottom: 1px solid #ddd; }
  .memory { margin-bottom: 16px; }
  
  .error { color: var(--boRed); display: flex; font-size: 16px; line-height: 20px }
  .error i { margin-right: 6px; }


  @media only screen and (min-width: 800px) {

    .eat_livraison .bg { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(10,10,10,.4) }
    .eat_livraison .int { left: 50%; right: initial; top: 60px; bottom: 60px; transform: translateX(-50%); width: 440px; border-radius: 30px; overflow-y: auto }

  }

</style>