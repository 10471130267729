<template>

  <svg class="close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>

</template>

<script>
export default {
  name: "close"
}
</script>

<style scoped>
  .close { position: fixed; left: 20px; top: 20px; z-index: 5; background: var(--blanc-button); fill: var(--black-sub); width: 40px; height: 40px; border-radius: 6px; cursor: pointer }
</style>