<template>
  <div class="eat_livraison" :data-show="show">
    <div @click="$emit('close')" class="bg"></div>
    <div class="int" v-if="$parent.boutique">
      <div class="int_top">

        <div @click="$emit('close')">
          <close></close>
        </div>

        <div class="title_a">Choisissez votre créneau</div>

        <div class="hours">
          <div class="day" v-for="(n, k) in $parent.boutique.listCreneauxNextDays" :key="k">
            <div class="t">{{ n.dayFormat }}</div>
<!--            {{ n.midi }}-->
            <div class="s" v-if="n.midi.length" >Midi</div>
            <div class="hours_line">
              <div class="hour" v-for="(m, k2) in n.midi" :key="k2" @click="select(n.day, n.dayFormat, m)" >{{ m }}</div>
            </div>
            <div class="s" v-if="n.soir.length">Soir</div>
            <div class="hours_line">
              <div class="hour" v-for="(m, k2) in n.soir" :key="k2"  @click="select(n.day, n.dayFormat, m)" >{{ m }}</div>
            </div>
            
            <div class="no_dispo" v-if="!n.midi.length && !n.soir.length">Non disponible</div>
<!--            <div class="hours" v-for="(m, k2) in n.soir" :key="k2">{{ m }}</div>-->
          </div>
        </div>


      </div>
      <!--      <div class="button_line">-->
      <!--        <button_plus :fw="true" :disabled="disabled" :rounded="true" color="green_simple" :click_not_can="true" @success="success" class="button_plus" txt="Continuer"></button_plus>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import close from '../global/close'

export default {
  name: "eat_livraison",
  data() {
    return {

    }
  },
  props: [ 'show' ],
  components: {
    close
  },
  mounted() {

  },
  methods: {
    select(day, dayFormat, k) {
      this.$emit('success', { day: day, dayFormat: dayFormat, hour: k })
    }
  }
}
</script>

<style scoped>

.eat_livraison { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 150; display: none }
.eat_livraison[data-show] { display: block }
.eat_livraison .int { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: #fff; z-index: 150;}
.eat_livraison .int .int_top { overflow-y: auto; position: absolute; top: 0; right: 0; bottom: 0; left: 0;  padding: 60px 0px 150px 0px; }

.title_a { padding: 0 20px }

.loader { margin-top: 20vh; }
/*.eat_livraison .button_line { position: absolute; bottom: 0; right: 0; left: 0; }*/

.eat_livraison .button_line { position: absolute; bottom: 0; left: 0; right: 0; padding: 20px 24px; background: #fff;box-shadow: 0 -4px 50px rgba(204,204,204,.5); z-index: 5; border-radius: 30px 30px 0 0 ;}
/*.eat_livraison .button_line .button_plus { w100% }*/

.hours { display: flex; flex-direction: column; }
.hours .hours_line { display: flex; flex-wrap: wrap; padding: 0 20px; }
.hours .hour { background: var(--gris-button); border-radius: 32px; height: 40px; padding: 0 16px; display: flex; align-items: center; justify-content: center;
  font-size: 16px; text-align: center; width: calc(100% / 3 - 11px); margin-bottom: 16px; cursor: pointer; margin-right: 16px;
}
.hours .hour:nth-child(3n + 3) { margin-right: 0; }
/*.hours .t { border-top: 1px solid var(--gris-button); }*/
/*.hours .day:first-child .t { border-top: none }*/
.hours .day .t { text-transform: capitalize; padding: 10px 20px; margin-top: 8px; margin-bottom: 12px; font-size: 18px; background: var(--gris) }
.hours .day .s { font-size: 16px; color: var(--black-sub); margin-bottom: 6px; padding: 0 20px; margin-top: 6px; }
.hours .day .no_dispo { font-size: 16px; color: var(--black-sub); margin-bottom: 6px; padding: 0 20px; margin-top: 6px;  }




@media only screen and (min-width: 800px) {

  .eat_livraison .bg { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(10,10,10,.4) }
  .eat_livraison .int { left: 50%; right: initial; top: 60px; bottom: 60px; transform: translateX(-50%); width: 440px; border-radius: 30px; overflow-y: auto }

}

</style>