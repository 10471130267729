<template>
  <div class="eat_livraison" :data-show="show">
    <div @click="$emit('close')" class="bg"></div>
    <div class="int" v-if="$parent.boutique">
      <div class="int_top">

        <div @click="$emit('close')">
          <close></close>
        </div>

        <div class="title_a">Choisissez votre créneau</div>
        
        <div class="hours">
          <div @click="select(n)" class="hour" v-for="(n, k) in $parent.boutique.nextOpen.creneaux" :key="k">
            {{ n }}
          </div>
        </div>
        

      </div>
<!--      <div class="button_line">-->
<!--        <button_plus :fw="true" :disabled="disabled" :rounded="true" color="green_simple" :click_not_can="true" @success="success" class="button_plus" txt="Continuer"></button_plus>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import close from '../global/close'

export default {
  name: "eat_livraison",
  data() {
    return {
      
    }
  },
  props: [ 'show' ],
  components: {
    close
  },
  mounted() {
    
  },
  methods: {
    select(k) {
      this.$emit('success', k)
    }
  }
}
</script>

<style scoped>

.eat_livraison { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 150; display: none }
.eat_livraison[data-show] { display: block }
.eat_livraison .int { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: #fff; z-index: 150;}
.eat_livraison .int .int_top { overflow-y: auto; position: absolute; top: 0; right: 0; bottom: 0; left: 0;  padding: 60px 20px 150px 20px; }

.loader { margin-top: 20vh; }
/*.eat_livraison .button_line { position: absolute; bottom: 0; right: 0; left: 0; }*/

.eat_livraison .button_line { position: absolute; bottom: 0; left: 0; right: 0; padding: 20px 24px; background: #fff;box-shadow: 0 -4px 50px rgba(204,204,204,.5); z-index: 5; border-radius: 30px 30px 0 0 ;}
/*.eat_livraison .button_line .button_plus { w100% }*/

.hours { display: flex; flex-direction: column; align-items: center; margin-top: 16px; }
.hours .hour { background: var(--gris-button); border-radius: 32px; height: 40px; padding: 0 16px; display: flex; align-items: center; justify-content: center;
  font-size: 16px; text-align: center; width: 140px; margin-bottom: 16px; cursor: pointer;
}




@media only screen and (min-width: 800px) {

  .eat_livraison .bg { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(10,10,10,.4) }
  .eat_livraison .int { left: 50%; right: initial; top: 60px; bottom: 60px; transform: translateX(-50%); width: 440px; border-radius: 30px; overflow-y: auto }

}

</style>